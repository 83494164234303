<template>
  <div class="mall g-con">
    <!-- Main body started -->
    <div class="sys_ad public_index_one">
      <p>
        <StaticImage
          :url="donationConfig?.bgImage || `Public/site_01.png`"
        ></StaticImage>
      </p>
    </div>
    <div class="public_index_two">
      <ul>
        <h3>{{ t('donation.label.Malltext') }}</h3>
        <li>
          <router-link to="/ebcare/application">
            <b>{{ t('donation.label.applyForHelp') }}</b>
          </router-link>
        </li>
        <li>
          <button @click="showStatement">
            <b>{{ t('donation.label.whatIsThis') }}</b>
          </button>
        </li>
      </ul>
    </div>

    <div class="public_index_four">
      <router-link to="/ebcare/public_list">
        <p><StaticImage url="site_44.png" alt="" /></p>
        <p>{{ t('donation.label.iWantHelp') }}</p>
        <p>{{ t('donation.label.iWantHelptext') }}</p>
        <p class="iconfont icon-you2"></p>
      </router-link>
    </div>

    <div class="public_index_three">
      <div class="title">
        <i></i>
        {{ t('home.missionList') }}
      </div>

      <div class="public_index_three_box">
        <div
          class="box"
          :style="`max-height: ${maxLength * 89}px; overflow: hidden;`"
        >
          <div class="no_data" v-if="!listItems || listItems.length === 0">
            <p>{{ t('common.message.noRecords') }}</p>
          </div>

          <transition-group v-else name="list-complete" tag="div">
            <router-link
              :key="item.scrollId || item.id"
              v-for="{ node: item } in listItems"
              :to="{
                name: 'RecipientDetail',
                query: { recipientId: item.recipient.id },
              }"
              class="list-complete-item"
            >
              <ol>
                <li>
                  <StaticImage :url="item.user.avatar" alt="" />
                  <span>{{ item.user.name }}</span>
                  {{ t('donation.label.donated') }}
                  <span>{{ formatMoney(item.amount) }}</span>
                </li>
                <li>
                  {{ t('donation.label.to') }}:
                  <span>{{ item.recipient.patientName }}</span>
                </li>
                <li>
                  {{ t('donation.label.time') }}:
                  <span>
                    {{
                      formatServerDateTime(item.insertedAt, 'YYYY-MM-DD HH:mm')
                    }}
                  </span>
                </li>
              </ol>
            </router-link>
          </transition-group>
        </div>
      </div>
    </div>
    <AModal
      v-model:visible="visible"
      :footer="null"
      centered
      title="What's This"
      wrapClassName="Sys_Popup"
      width="85%"
    >
      <div class="Sys_Popup_wrap" v-if="donationConfig">
        <div class="box" v-html="donationConfig.introduction"></div>
      </div>
    </AModal>
  </div>
</template>
<script>
import { ref, computed, inject } from 'vue';
import { useI18n } from '@/lang';
import { formatMoney, formatServerDateTime } from '@/utils/formatter';
import StaticImage from '@/components/StaticImage.vue';
export default {
  components: {
    StaticImage,
  },
  setup() {
    const { t } = useI18n();

    let visible = ref(false);
    const showStatement = () => (visible.value = true);

    const donationConfig = inject('donationConfig');

    const _listItems = ref([]);
    let listItems = computed({
      get: () => _listItems.value,
      set: (val) => (_listItems.value = val),
    });

    let scrollId = 0;
    setInterval(() => {

      let newItem;
      listItems.value.unshift({
        node: { ...newItem.node, scrollId: scrollId++ },
      });
    }, 3000);

    return {
      t,
      formatMoney,
      formatServerDateTime,
      visible,
      showStatement,
      listItems,
      donationConfig,
    };
  },
};
</script>
<style>
.list-complete-item {
  height: 89px;
}

.list-complete-enter-active,
.list-complete-leave-active {
  transition: all 1s ease;
}

.list-complete-enter-from {
  height: 0;
  opacity: 0;
  transform: translateY(-30px);
}

.list-complete-leave-to {
  height: 0;
  opacity: 0;
}
</style>
